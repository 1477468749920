<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>供片系统设备方</el-breadcrumb-item>
                <el-breadcrumb-item>内部管理</el-breadcrumb-item>
                <el-breadcrumb-item>账户管理</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
                <el-button v-if="editOptions.open" type="primary" @click="editFormVisible = true">新增</el-button>
                <!--                <el-button v-if="editOptions.open" type="primary" @click="addPlatformVisible = true">添加平台用户</el-button>-->
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label" />
                        </template>
                    </template>
                    <el-table-column min-width="200" label="操作">
                        <!--对自己不能有任何操作,对管理员可以停用和重置密码-->
                        <template slot-scope="scope">
                            <template v-if="g_getUser() && scope.row.id !== g_getUser().uuid">
                                <template v-if="!scope.row.root">
                                    <el-button type="text" @click="editHandle(scope.row.id)">
                                        修改 <i class="el-icon-edit"></i>
                                    </el-button>
                                    <el-divider direction="vertical"></el-divider>
                                    <template>
                                        <el-popconfirm
                                                confirm-button-text='好的'
                                                cancel-button-text='不用了'
                                                icon="el-icon-delete"
                                                icon-color="red"
                                                :title="`删除账号${scope.row.name}？`"
                                                @confirm="delHandle(scope.row.id)"
                                        >
                                            <el-button slot="reference" type="text">
                                                删除 <i class="el-icon-delete"></i>
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                    <el-divider direction="vertical"></el-divider>
                                </template>
                                <el-button v-if="scope.row.status === 1" type="text" @click="enableHandle(scope.row.id)">
                                    启用 <i class="el-icon-open"></i>
                                </el-button>
                                <el-button v-if="scope.row.status === 0" type="text" @click="disableHandle(scope.row.id)">
                                    停用 <i class="el-icon-turn-off"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <template>
                                    <el-popconfirm
                                            confirm-button-text='好的'
                                            cancel-button-text='不用了'
                                            icon="el-icon-info"
                                            icon-color="red"
                                            :title="`重置账号${scope.row.name}密码？`"
                                            @confirm="resetPass(scope.row.id)"
                                    >
                                        <el-button slot="reference" type="text">
                                            重置密码 <i class="el-icon-lock"></i>
                                        </el-button>
                                    </el-popconfirm>
                                </template>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <el-dialog :title="editOptions.id?'修改用户':'添加用户'" :visible.sync="editFormVisible" width="50%" center append-to-body :before-close="closeEditForm">
                <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import EditForm from "../common/EditForm";
    import {Search_Type} from "../../constants";
    const prefix = '/sys/user';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id) => `${prefix}/one/${id}`
    const postUrl = `${prefix}/save`;
    const putUrl = (id) => `${prefix}/update/${id}`
    const deleteUrl = (id) => `${prefix}/delete/${id}`
    const resetUrl = (id) => `${prefix}/reset/${id}`
    const enableUrl = (id)=>`${prefix}/enable/${id}`
    const disableUrl = (id)=>`${prefix}/disable/${id}`
    export default {
        components: {SearchForm,EditForm},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                    postUrl: postUrl,
                    getOneUrl: getOneUrl,
                    putUrl: putUrl,
                },
                editFormVisible: false,
            }
        },
        props:['params'],
        created() {
            //预加载初始密码
            this.g_getInitPass();
            this.g_setupMetadataConfig(this, 'Menu3_User')
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.g_loadDynamicSelectData(this)
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            delHandle(id){
                this.$axios.delete(deleteUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getUsers();
                        }
                    });
                })
            },
            resetPass(id){
                this.$axios.put(resetUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功,新密码为${this.g_getInitPass()}`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            // this.getTableData()
                        }
                    });
                })
            },
            editHandle(id){
                this.editOptions.id = id
                this.editFormVisible = true
            },
            closeEditForm(result){
                this.editOptions.id = null
                this.editFormVisible = false
                if(result){
                    this.getTableData()
                }
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
        }
    }
</script>

<style scoped>
    :deep(.el-form-item){
        margin-bottom: 0;
    }
</style>